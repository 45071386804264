import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FullProfileDto, Paginated, ProfileDto, UserDto } from '@ledsreact/data-models';

@Injectable()
export class UserHttpService {
  constructor(
    private http: HttpClient,
    @Inject('baseUrl') private baseUrl: string,
    @Inject('baseUrl$') private baseUrl$: Observable<string>
  ) {
    this.baseUrl$.subscribe((baseUrl) => {
      this.baseUrl = baseUrl;
    });
  }

  public login(email: string, password: string): Observable<UserDto> {
    return this.http.post<UserDto>(`${this.baseUrl}/user/login`, { email, password });
  }

  public askResetPassword(email: string): Observable<null> {
    return this.http.post<null>(`${this.baseUrl}/user/ask-reset-password`, { email });
  }

  public getUsers(httpParams?: {}): Observable<Paginated<UserDto>> {
    return this.http.get<Paginated<UserDto>>(`${this.baseUrl}/user`, { params: httpParams ? httpParams : null });
  }

  public resetPassword(password: string): Observable<UserDto> {
    return this.http.post<UserDto>(`${this.baseUrl}/user/reset-password`, { password });
  }

  public updatePassword(oldPassword: string, newPassword: string): Observable<UserDto> {
    return this.http.post<UserDto>(`${this.baseUrl}/user/update-password`, { oldPassword, newPassword });
  }

  public setPassword(password: string): Observable<ProfileDto> {
    return this.http.post<ProfileDto>(`${this.baseUrl}/new-user/set-password`, { password });
  }

  public getNewUser(): Observable<FullProfileDto> {
    return this.http.get<FullProfileDto>(`${this.baseUrl}/new-user/details`);
  }

  public addUser(email: string, firstname: string, lastname: string, password: string): Observable<UserDto> {
    return this.http.post<UserDto>(`${this.baseUrl}/user`, { email, firstname, lastname, password });
  }

  public updateUser(
    userId: number,
    email: string,
    firstname: string,
    lastname: string,
    phone: string,
    password: string
  ): Observable<UserDto> {
    return this.http.patch<UserDto>(`${this.baseUrl}/user/${userId}`, {
      email,
      firstname,
      lastname,
      phone,
      password,
    });
  }

  public deleteUser(userId: number): Observable<UserDto> {
    return this.http.delete<UserDto>(`${this.baseUrl}/user/${userId}`);
  }

  public logout(): Observable<null> {
    return this.http.post<null>(`${this.baseUrl}/user/logout`, null);
  }
}
