import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ChartDTO, ChartSerie, ChartDataType } from '@ledsreact/data-models';
import * as Highcharts from 'highcharts';
import xrange from 'highcharts/modules/xrange';
import { GraphUtil } from '@webplatform/shared/util/graph.util';
import { Colors } from '@webplatform/shared/data-model/graph-type/colors.enum';

xrange(Highcharts);

@Component({
  selector: 'app-areaspline-graph',
  templateUrl: '../../graph.template.html',
})
export class AreasplineGraphComponent implements OnChanges {
  @Input() data: ChartDTO[];
  @Input() showTimeIndicator = true;
  @Input() set currentTime(value: number) {
    if (
      value !== undefined &&
      this.chart &&
      this.timeRangeData &&
      !this._isCurrentTimeHidden() &&
      this.showTimeIndicator
    ) {
      this._currentTime = value;
      this.updateTimeIndicator();
    }
  }

  @Output() readonly timePositionChange = new EventEmitter<number>();

  private _currentTime = 0;
  private chart: Highcharts.Chart;
  private timeRangeData: { min: number; max: number } = null;
  private timeIndicator: Highcharts.SVGElement;
  private indicatorHandle: Highcharts.SVGElement;

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options;
  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chart = chart;
    this._setupTimeIndicator();
  };

  initOptions: Highcharts.Options = {
    chart: {
      type: 'areaspline',
      height: 50 + '%',
      events: {
        load() {
          // No need to store chart reference here
        },
      },
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      lineColor: Colors.GREY,
      labels: {
        enabled: false,
      },
      tickLength: 0,
    },
    yAxis: {
      title: null,
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          radius: 0,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        turboThreshold: 10000000,
      },
      areaspline: {
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
        },
      },
    },
  };

  constructor(private _cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data) {
      this.chartOptions = null;
      this._cd.detectChanges();
      this.chartOptions = { ...this.initOptions };
      this.chartOptions.colors = [];
      this.chartOptions.series = [];

      this.extractTimeRangeData();

      this.data.forEach((dataset: ChartDTO, index: number) => {
        dataset?.series?.forEach((serie: ChartSerie) => {
          this.chartOptions.colors.push(GraphUtil.compareBorderColorSet[index]);
          this.chartOptions.series.push({
            ...serie,
            ...{
              type: 'areaspline',
              states: {
                hover: {
                  enabled: false,
                },
                inactive: {
                  enabled: false,
                },
              },
              fillColor: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: GraphUtil.getColorStop(
                  serie.data.map((coordinate: { x: number; y: number; zoneIndex?: number }) => coordinate.y),
                  GraphUtil.compareRgbColorSet[index][0],
                  GraphUtil.compareRgbColorSet[index][1]
                ),
              },
            },
          });
        });
      });

      // Add chart events for pointer interactions to enable time indicator dragging
      this.chartOptions.chart.events = {
        ...this.chartOptions.chart.events,
        click: (e) => this.handleChartClick(e),
      };
    }
  }

  private extractTimeRangeData() {
    if (!this.data || !this.data[0]?.series || !this.data[0].series[0]?.data?.length) {
      this.timeRangeData = null;
      return;
    }

    const seriesData = this.data[0].series[0].data;
    // Handle both simple data points and {x,y} objects
    const xValues = seriesData.map((point) => {
      return typeof point === 'object' && 'x' in point ? point.x : 0;
    });

    this.timeRangeData = {
      min: Math.min(...xValues),
      max: Math.max(...xValues),
    };
  }

  private _isCurrentTimeHidden(): boolean {
    if (!this.data || this.data.length === 0) {
      return false;
    }
    const dataType = this.data[0].dataType;
    return [
      ChartDataType.SPEED_OVER_DISTANCE,
      ChartDataType.RATIO_FORCE_VELOCITY,
      ChartDataType.FORCE_VELOCITY_PROFILE,
    ].includes(dataType);
  }

  private _setupTimeIndicator() {
    if (!this.chart || !this.timeRangeData || this._isCurrentTimeHidden() || !this.showTimeIndicator) {
      return;
    }

    // Remove existing indicator if any
    if (this.timeIndicator) {
      this.timeIndicator.destroy();
    }
    if (this.indicatorHandle) {
      this.indicatorHandle.destroy();
    }

    // Create a draggable time indicator
    const renderer = this.chart.renderer;
    const plotTop = this.chart.plotTop;
    const plotHeight = this.chart.plotHeight;

    // Convert time to x position - add the required second parameter
    const xPos = this.chart.xAxis[0].toPixels(this._currentTime, false);

    // Draw indicator line using path with proper typing
    this.timeIndicator = renderer
      .path([
        ['M', xPos, plotTop],
        ['L', xPos, plotTop + plotHeight],
      ])
      .attr({
        stroke: '#FF4500', // Orange-red color
        'stroke-width': 2,
        zIndex: 20,
      })
      .add();

    // Add draggable circle at top of indicator
    this.indicatorHandle = renderer
      .circle(xPos, plotTop, 6)
      .attr({
        fill: '#FF4500',
        stroke: '#FFFFFF',
        'stroke-width': 2,
        zIndex: 21,
        cursor: 'ew-resize',
      })
      .add()
      .css({
        cursor: 'ew-resize',
      });

    // Make the indicator draggable
    let isDragging = false;

    // Add mouse events for drag
    Highcharts.addEvent(this.indicatorHandle.element, 'mousedown', (e: MouseEvent) => {
      e.stopPropagation();
      isDragging = true;
    });

    Highcharts.addEvent(this.chart.container, 'mousemove', (e: MouseEvent) => {
      if (isDragging) {
        const chartOffset = Highcharts.offset(this.chart.container);
        const chartX = e.clientX - chartOffset.left;
        const plotLeft = this.chart.plotLeft;
        const plotWidth = this.chart.plotWidth;

        // Constrain x position to plot area
        const newXPos = Math.max(plotLeft, Math.min(plotLeft + plotWidth, chartX));

        // Update indicator position with proper path format
        this.timeIndicator.attr({
          d: [
            ['M', newXPos, plotTop],
            ['L', newXPos, plotTop + plotHeight],
          ],
        });

        this.indicatorHandle.attr({
          cx: newXPos,
        });

        // Convert position to time value
        const newTime = this.chart.xAxis[0].toValue(newXPos);

        // Emit the time position change
        this.timePositionChange.emit(newTime);
      }
    });

    Highcharts.addEvent(document, 'mouseup', () => {
      isDragging = false;
    });
  }

  private updateTimeIndicator() {
    if (
      !this.chart ||
      !this.timeIndicator ||
      !this.timeRangeData ||
      this._isCurrentTimeHidden() ||
      !this.showTimeIndicator
    ) {
      return;
    }

    // Update indicator position - add the required second parameter
    const xPos = this.chart.xAxis[0].toPixels(this._currentTime, false);
    const plotTop = this.chart.plotTop;
    const plotHeight = this.chart.plotHeight;

    // Update path with proper format
    this.timeIndicator.attr({
      d: [
        ['M', xPos, plotTop],
        ['L', xPos, plotTop + plotHeight],
      ],
    });

    // Update handle position
    if (this.indicatorHandle) {
      this.indicatorHandle.attr({
        cx: xPos,
      });
    }
  }

  private handleChartClick(e: Highcharts.PointerEventObject) {
    if (!this.chart || this._isCurrentTimeHidden() || !this.showTimeIndicator) {
      return;
    }

    // Get the x-coordinate in terms of axis values
    const xPos = this.chart.xAxis[0].toValue(e.chartX - this.chart.plotLeft);

    // Emit the time position
    this.timePositionChange.emit(xPos);
  }
}
