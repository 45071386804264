import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AppService {
  static websocketUrl: string;

  private _region: string | null = null;

  constructor(private readonly _httpClient: HttpClient, @Inject('baseUrl') private readonly _baseUrl: string) {}

  getRegion(): Promise<string> {
    if (this._region) {
      return Promise.resolve(this._region);
    }

    return firstValueFrom(
      this._httpClient.get(`${this._baseUrl}/region`, { responseType: 'text' }).pipe(
        map((region) => {
          this._region = region;
          return region;
        })
      )
    );
  }
}
