import { WebplatformEnvironment } from './environment.type';
import { version } from './version';

export const environment: WebplatformEnvironment = {
  production: true,
  environmentName: 'staging',
  trackingEnvironmentName: 'staging',
  api: 'https://api.staging.ledsreact.com',
  ws: 'wss://ws.staging.ledsreact.com',
  version,
  isRestartPostProcessorAllowed: 'always',
  videoAppUrl: 'https://video-staging.ledsreact.com',
};
