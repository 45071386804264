import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class FeatureMenuHttpService {
  constructor(
    private http: HttpClient,
    @Inject('baseUrl') private baseUrl: string,
    @Inject('baseUrl$') private baseUrl$: Observable<string>
  ) {
    this.baseUrl$.subscribe((baseUrl) => {
      this.baseUrl = baseUrl;
    });
  }

  public getFeatureMenu(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/feature-menu`);
  }
}
