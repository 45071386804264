import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GraphType } from '@webplatform/shared/data-model/graph-type/graph-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChartDataType, ChartDTO, ExerciseUid, FeatureEnum, Video, VideoQueryDto } from '@ledsreact/data-models';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { StringMultipleSelectModalComponent } from '../../multiple-select-modal/number-multi-select-modal/string-multiple-select-modal.component';
import { ChartsService } from '@webplatform/shared/services/charts.service';
import { InsightsService } from 'apps/webplatform/src/app/authenticated/insights/insights.service';
import { JsUtil } from '@webplatform/shared/util/js.util';
import { UserService } from '@webplatform/shared/services/user.service';
import { VideoHttpService } from '@ledsreact/angular-http-services';
import { VideoWrapperComponent } from '../../video-wrapper/video-wrapper.component';

@UntilDestroy()
@Component({
  selector: 'app-small-graphs-wrapper',
  templateUrl: './small-graphs-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmallGraphsWrapperComponent implements OnInit {
  @Input() attemptId: number;
  @Input() set charts(value: ChartDTO[][]) {
    this._allChartsList = value;
    this.setChartsToDisplay();
  }

  @Input() preventRouting: boolean = false;
  @Input() hasAdvancedGraph: boolean;
  @Input() exerciseUid: ExerciseUid;

  @Output() readonly chartToSelect: EventEmitter<ChartDataType> = new EventEmitter<ChartDataType>();

  @ViewChild(VideoWrapperComponent) videoWrapper: VideoWrapperComponent;

  private _initDataTypeList: ChartDataType[];
  private _allChartsList: ChartDTO[][];

  // Current video time in seconds
  currentVideoTime = 0;

  // Videos available for this exercise attempt
  videos: Video[] = [];

  // Currently selected video
  selectedVideo: Video = null;

  // Flag to prevent circular updates between video and graph
  private _isUpdatingFromVideo = false;
  private _isUpdatingFromGraph = false;

  readonly graphTypeColumn: GraphType = GraphType.column;
  readonly graphTypeAreaspline: GraphType = GraphType.areaspline;

  dataTypeToDisplay: ChartDataType[];
  chartsToDisplay: ChartDTO[][];

  constructor(
    private _dialog: MatDialog,
    private _chartsService: ChartsService,
    private _insightsService: InsightsService,
    private _userService: UserService,
    private _cd: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _router: Router,
    private _videoHttpService: VideoHttpService
  ) {}

  ngOnInit() {
    this.dataTypeToDisplay = this._chartsService.getDataTypeVisible();
    this._initDataTypeList = this._chartsService.getDefaultDataTypeVisible();
    this.setChartsToDisplay();
    // Override default data type visible - just show everything
    this.dataTypeToDisplay = this._allChartsList[0].map((chart: ChartDTO) => chart.dataType);

    // Check if video is enabled and fetch videos for the exercise attempt
    if (this.hasVideoEnabled() && this.attemptId) {
      const options: VideoQueryDto = { withUrl: true };
      this._videoHttpService
        .getVideosByExerciseAttempt(this.attemptId, options)
        .pipe(untilDestroyed(this))
        .subscribe((videos: Video[]) => {
          // Filter only COMPLETE videos that have a signedUrl
          this.videos = videos.filter((video) => video.processingStatus === 'COMPLETE' && video.signedUrl);
          this._cd.markForCheck();
        });
    }

    this._cd.markForCheck();
  }

  /**
   * Handles time changes from the video
   * @param time Current video time in seconds
   */
  onVideoTimeChange(time: number) {
    if (this._isUpdatingFromGraph) {
      return; // Prevent circular updates
    }

    this._isUpdatingFromVideo = true;
    this.currentVideoTime = time;
    this._cd.detectChanges();

    // Reset flag after a short delay to prevent blocking legitimate updates
    setTimeout(() => {
      this._isUpdatingFromVideo = false;
    }, 50);
  }

  /**
   * Handles selected video changes
   * @param video The selected video
   */
  onSelectedVideoChange(video: Video) {
    this.selectedVideo = video;
    this._cd.detectChanges();
  }

  /**
   * Handles time position changes from the graph
   * @param time Time position in seconds from the graph
   */
  onGraphTimeChange(time: number) {
    if (this._isUpdatingFromVideo) {
      return; // Prevent circular updates
    }

    this._isUpdatingFromGraph = true;
    this.currentVideoTime = time;

    // Update the video player's time position
    if (this.videoWrapper) {
      this.videoWrapper.setCurrentTime(time);
    }

    this._cd.detectChanges();

    // Reset flag after a short delay to prevent blocking legitimate updates
    setTimeout(() => {
      this._isUpdatingFromGraph = false;
    }, 50);
  }

  setChartsToDisplay() {
    this._userService.handleFeatureFlagGraph(this._allChartsList);
    if (this._allChartsList && this.dataTypeToDisplay) {
      this.chartsToDisplay = [];
      const allChartList = [...this._allChartsList];
      const maxChartNumber: number = Math.max(...allChartList.map((chartList: ChartDTO[]) => chartList.length));
      for (let i = 0; i < maxChartNumber; i++) {
        this.chartsToDisplay.push([]);
        allChartList?.forEach((chartByPlayer: ChartDTO[], index: number) => {
          if (this.dataTypeToDisplay.includes(chartByPlayer[0]?.dataType)) {
            if (chartByPlayer[i]) {
              this.chartsToDisplay[i].push(chartByPlayer[i]);
            } else if (index === 0 && allChartList.length > 1 && allChartList[1][index]) {
              this.chartsToDisplay[i].push({ chartType: allChartList[1][index].chartType });
            } else {
              this.chartsToDisplay[i].push(null);
            }
          }
        });
        this.chartsToDisplay[i] = this._setUnits(this.chartsToDisplay[i]);
      }
    } else {
      this.chartsToDisplay = null;
    }
  }

  hasVideoEnabled(): boolean {
    return this._userService.isFeatureEnabled(FeatureEnum.VIDEO);
  }

  displayDetails(event: Event, index: number) {
    if (this.preventRouting) {
      event.stopImmediatePropagation();
      this.chartToSelect.emit(this._allChartsList[0][index].dataType);
    } else {
      this._router.navigate([AppRoutingEnum.CHART, this._allChartsList[0][index].dataType, AppRoutingEnum.RESULTS], {
        relativeTo: this._route.parent,
      });
    }
  }

  trackByFn(index: number, _: ChartDTO[]) {
    return index;
  }

  openDataTypeSelectModal() {
    const defaultDataType: { value: ChartDataType[]; readonly: boolean; dataTypeGroup: string } = {
      value: [],
      readonly: true,
      dataTypeGroup: 'default',
    };
    const advancedDataType: { value: ChartDataType[]; dataTypeGroup: string } = {
      value: [],
      dataTypeGroup: 'advanced',
    };
    this._allChartsList[0]?.forEach((chart: ChartDTO) => {
      if (this._initDataTypeList?.includes(chart?.dataType)) {
        defaultDataType.value.push(chart.dataType);
      } else {
        advancedDataType.value.push(chart?.dataType);
      }
    });
    const allDataList: { value: ChartDataType[]; dataTypeGroup: string }[] = [defaultDataType, advancedDataType];
    const dialogRef = this._dialog.open(StringMultipleSelectModalComponent, {
      data: {
        // translateKey: 'dataType',
        valueList: allDataList,
        itemKey: null,
        initValue: this.dataTypeToDisplay,
        selectedItemsValue: this.dataTypeToDisplay,
        title: 'LABEL.showLessMoreGraphs',
        buttonKey: 'BUTTON.apply',
        sectionFieldsToDisplay: [{ field: 'dataTypeGroup', translateKey: 'LABEL' }],
      },
      autoFocus: false,
      panelClass: ['modal-container', 'select-modal'],
    });

    dialogRef
      .beforeClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value: ChartDataType[]) => {
        if (value) {
          this.dataTypeToDisplay = value;
          this.setChartsToDisplay();
          this._chartsService.setDataTypeVisible(this.dataTypeToDisplay);
          this._cd.markForCheck();
        }
      });
  }

  private _setUnits(initialCharts: ChartDTO[]) {
    const charts = JsUtil.deepCopy(initialCharts);
    if (!charts || !charts.length) {
      return charts;
    }
    for (const c of charts) {
      c.insights = this._insightsService.setInsightsUnitSystem([c.insights], this._chartsService.getUnitSystem())[0];
    }
    const units = this._chartsService.getAvailableUnits(charts[0].dataType, this._chartsService.getUnitSystem());
    if (!units.length) {
      return charts;
    }

    return this._chartsService.setChartUnit(charts, units[0]);
  }
}
