import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UpdatableUserDto, UserDto } from '@ledsreact/data-models';

@Injectable()
export class MeHttpService {
  constructor(
    private http: HttpClient,
    @Inject('baseUrl') private baseUrl: string,
    @Inject('baseUrl$') private baseUrl$: Observable<string>
  ) {
    this.baseUrl$.subscribe((baseUrl) => {
      this.baseUrl = baseUrl;
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public getMe(httpParams?: {}): Observable<UserDto> {
    return this.http.get<UserDto>(`${this.baseUrl}/user/me`, { params: httpParams ? httpParams : null });
  }

  public updateMe(user: UpdatableUserDto): Observable<UserDto> {
    return this.http.patch<UserDto>(`${this.baseUrl}/user/me`, user);
  }

  public verify(): Observable<UserDto> {
    return this.http.post<UserDto>(`${this.baseUrl}/user/me/verify`, {});
  }

  /**
   * A Base64 image
   */
  public addImage(image: string): Observable<UserDto> {
    return this.http.post<UserDto>(`${this.baseUrl}/user/me/image`, { image });
  }
}
