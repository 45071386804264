<div class="video-container">
  <ng-container *ngIf="videos && videos.length > 0">
    <div class="video-tabs">
      <div *ngFor="let video of videos; let i = index" class="tab-item" [class.active]="selectedIndex === i">
        <button (click)="selectVideo(i)">
          {{ getVideoTabLabel(i, video) }}
        </button>
        <button class="delete-button" (click)="confirmDeleteVideo(i, video)">
          <i class="material-icons">delete</i>
        </button>
      </div>
    </div>
    <div class="video-player-wrapper">
      <app-video-player [options]="videoOptions" (currentTimeChange)="onVideoTimeChange($event)"></app-video-player>
    </div>
  </ng-container>

  <div class="empty-state" *ngIf="!videos || videos.length === 0">
    <div class="empty-state-content">
      <div class="icon-container">
        <i class="material-icons">videocam_off</i>
      </div>
      <h3>No Videos Available</h3>
      <p>Upload videos to see them here</p>
      <a [href]="videoAppUrl" target="_blank" class="upload-button">
        <i class="material-icons">cloud_upload</i>
        Upload Videos
      </a>
    </div>
  </div>
</div>
