import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Colors } from '@webplatform/shared/data-model/graph-type/colors.enum';
import { ChartDTO } from '@ledsreact/data-models';
import { GraphUtil } from '@webplatform/shared/util/graph.util';
import { ChartsService } from '@webplatform/shared/services/charts.service';

@Component({
  selector: 'app-column-graph',
  templateUrl: '../../graph.template.html',
})
export class ColumnGraphComponent implements OnChanges {
  @Input() data: ChartDTO[];
  @Input() showTimeIndicator = true;
  @Input() set currentTime(value: number) {
    if (value !== undefined && this.chart && this.showTimeIndicator) {
      this._currentTime = value;
      this.highlightColumnAtTime();
    }
  }

  @Output() readonly timePositionChange = new EventEmitter<number>();

  private _currentTime = 0;
  private chart: Highcharts.Chart;
  private highlightedPoint: any = null;
  private timeMapping: Map<number, number> = new Map(); // Maps x values to point indexes

  // DO NOT REMOVE. This is needed for proper typescript type definition
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;

  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chart = chart;
    this.setupTimeMapping();
    this.setupClickHandling();
  };

  initOptions: Highcharts.Options = {
    colors: [Colors.RED_LIGHT],
    chart: {
      height: 50 + '%',
      type: 'column',
      events: {
        click(e) {
          // Will be replaced in setupClickHandling
        },
      },
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0.1,
        states: {
          select: {
            color: '#FF4500', // Highlight color
            borderColor: '#FF4500',
            borderWidth: 2,
          },
        },
        point: {
          events: {
            click() {
              // Will be replaced in setupClickHandling
            },
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      title: null,
      labels: {
        enabled: false,
      },
    },
    series: [],
  };

  constructor(private _cd: ChangeDetectorRef, private _chartService: ChartsService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data) {
      this.chartOptions = null;
      this._cd.detectChanges();
      this.chartOptions = { ...this.initOptions };
      this.chartOptions.series = [];
      this.data.forEach((chart: ChartDTO, index: number) => {
        chart.series?.forEach((serie) => {
          this.chartOptions.series.push({
            type: 'column',
            data: serie.data.map(() => 0),
            stack: index,
            minPointLength: 2,
            color: GraphUtil.compareBorderColorSet[index],
            borderWidth: 0,
            states: {
              inactive: {
                enabled: false,
              },
              hover: {
                enabled: false,
              },
              select: {
                color: GraphUtil.compareBorderColorSet[index],
                borderColor: '#FF4500',
                borderWidth: 2,
              },
            },
          });
          this.chartOptions.series.push({
            type: 'column',
            borderWidth: 0,
            data: serie.data,
            color: GraphUtil.compareLighterColorSet[index],
            stack: index,
            states: {
              inactive: {
                enabled: false,
              },
              hover: {
                color: GraphUtil.compareLighterColorSet[index],
              },
              select: {
                color: '#FF4500', // Highlight color
                borderColor: '#FF4500',
                borderWidth: 0,
              },
            },
          });
        });
      });
      this.chartOptions.xAxis = {
        lineColor: Colors.GREY,
        labels: { enabled: false },
        tickLength: 0,
      };
      this.chartOptions = this._chartService.removeReactionTimeIfNeeded(this.data, this.chartOptions);
      this._cd.detectChanges();
    }
  }

  /**
   * Sets up mapping between time values and point indexes for quick lookup
   */
  private setupTimeMapping() {
    if (!this.chart || !this.chart.series || this.chart.series.length === 0) {
      return;
    }

    this.timeMapping.clear();

    // We focus on the data series, which is every second series (odd indexes)
    for (let i = 1; i < this.chart.series.length; i += 2) {
      const series = this.chart.series[i];

      series.points.forEach((point, index) => {
        if (point && point.x !== undefined) {
          this.timeMapping.set(point.x, index);
        }
      });
    }
  }

  /**
   * Sets up event handling for clicking on columns
   */
  private setupClickHandling() {
    if (!this.chart) {
      return;
    }

    // Add click handler to points
    this.chart.series.forEach((series) => {
      series.points.forEach((point) => {
        if (point && point.graphic) {
          point.graphic.on('click', () => {
            if (this.showTimeIndicator) {
              this.handlePointClick(point);
            }
          });
        }
      });
    });

    // Add click handler to chart background
    Highcharts.addEvent(this.chart.container, 'click', (e: MouseEvent) => {
      if (!this.showTimeIndicator) {
        return;
      }

      // Only handle clicks on the plot area, not on points
      if (e.target && (e.target as HTMLElement).tagName !== 'rect') {
        return;
      }

      const chartOffset = Highcharts.offset(this.chart.container);
      const clickX = e.clientX - chartOffset.left - this.chart.plotLeft;
      const clickTime = this.chart.xAxis[0].toValue(clickX + this.chart.plotLeft);

      this.timePositionChange.emit(clickTime);
    });
  }

  /**
   * Handles clicks on a specific column/point
   */
  private handlePointClick(point: Highcharts.Point) {
    if (!point || point.x === undefined) {
      return;
    }

    // Emit the time position of the clicked point
    this.timePositionChange.emit(point.x);
  }

  /**
   * Highlights the column closest to the current time
   */
  private highlightColumnAtTime() {
    if (!this.chart || !this.chart.series.length || !this.showTimeIndicator) {
      return;
    }

    // Clear previous highlight
    if (this.highlightedPoint) {
      this.highlightedPoint.select(false);
    }

    // We need to work with data series (every second series in our setup)
    const dataSeries = this.chart.series.filter((_, i) => i % 2 === 1);
    if (!dataSeries.length || !dataSeries[0].points.length) {
      return;
    }

    // Calculate cumulative time ranges
    let cumulativeTime = 0;
    const timeRanges = [];

    // Use the first data series to calculate time ranges
    dataSeries[0].points.forEach((point) => {
      if (point && point.y !== undefined) {
        const startTime = cumulativeTime;
        cumulativeTime += point.y;
        timeRanges.push({
          start: startTime,
          end: cumulativeTime,
          pointIndex: point.index,
        });
      }
    });

    // Find which range contains the current time
    let targetPointIndex = -1;
    for (const range of timeRanges) {
      if (this._currentTime >= range.start && this._currentTime < range.end) {
        targetPointIndex = range.pointIndex;
        break;
      }
    }

    // If we're at or past the last time point, highlight the last column
    if (targetPointIndex === -1 && cumulativeTime > 0 && this._currentTime >= cumulativeTime) {
      targetPointIndex = timeRanges[timeRanges.length - 1].pointIndex;
    }

    // Highlight the corresponding points in all series
    if (targetPointIndex !== -1) {
      for (const series of this.chart.series) {
        const point = series.points[targetPointIndex];
        if (point) {
          point.select(true, true); // true = select, true = accumulate (don't unselect others)
          this.highlightedPoint = point;
        }
      }
    }
  }
}
