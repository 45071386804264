import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Video,
  CreateVideoDto,
  CreateVideoResponse,
  UpdateVideoStatusDto,
  VideoQueryDto,
} from '@ledsreact/data-models';

@Injectable()
export class VideoHttpService {
  constructor(
    protected http: HttpClient,
    @Inject('baseUrl') protected baseUrl: string,
    @Inject('baseUrl$') protected baseUrl$: Observable<string>
  ) {
    this.baseUrl$.subscribe((baseUrl) => {
      this.baseUrl = baseUrl;
    });
  }

  /**
   * Create a new video and get a signed upload URL
   */
  createVideo(createVideoDto: CreateVideoDto): Observable<CreateVideoResponse> {
    return this.http.post<CreateVideoResponse>(`${this.baseUrl}/video`, createVideoDto);
  }

  /**
   * Get a video by ID
   */
  getVideo(id: string, options?: VideoQueryDto): Observable<Video> {
    const params = options ? this.createParams(options) : {};
    return this.http.get<Video>(`${this.baseUrl}/video/${id}`, { params });
  }

  /**
   * Get all videos for an exercise attempt
   */
  getVideosByExerciseAttempt(exerciseAttemptId: number, options?: VideoQueryDto): Observable<Video[]> {
    const params = options ? this.createParams(options) : {};
    return this.http.get<Video[]>(`${this.baseUrl}/video/by-exercise-attempt/${exerciseAttemptId}`, { params });
  }

  /**
   * Update video processing status
   */
  updateVideoStatus(id: string, updateStatusDto: UpdateVideoStatusDto): Observable<Video> {
    return this.http.patch<Video>(`${this.baseUrl}/video/${id}/status`, updateStatusDto);
  }

  /**
   * Delete a video
   */
  deleteVideo(id: string): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(`${this.baseUrl}/video/${id}`);
  }

  /**
   * Create query parameters from an object, filtering out undefined/null values
   */
  private createParams(options: Record<string, any>): Record<string, string> {
    return Object.fromEntries(Object.entries(options).filter(([_, value]) => value !== undefined && value !== null));
  }
}
