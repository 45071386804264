import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'app-video-player',
  template: `
    <div class="video-container">
      <div class="video-wrapper">
        <video #target class="video-js"></video>
      </div>
      <div class="custom-controls">
        <div class="controls-bottom">
          <div class="time-display">{{ formattedTime }}</div>
          <div class="buttons-container">
            <button (click)="previousFrame()" title="Previous Frame">
              <i class="frame-icon">←</i>
            </button>
            <button (click)="togglePlayPause()" class="play-pause-button" title="Play/Pause">
              <i class="control-icon">{{ isPlaying ? '❚❚' : '▶' }}</i>
            </button>
            <button (click)="nextFrame()" title="Next Frame">
              <i class="frame-icon">→</i>
            </button>
          </div>
          <div class="spacer"></div>
        </div>
        <div class="timeline-container">
          <input
            type="range"
            class="timeline-slider"
            min="0"
            [max]="duration"
            [value]="currentTime"
            (input)="onTimelineChange($event)"
            step="0.01"
          />
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./video-player.component.css'],
})
export class VideoPlayerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('target', { static: true }) target: ElementRef;

  // See options: https://videojs.com/guides/options
  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    sources: {
      src: string;
      type: string;
    }[];
  };

  @Output() readonly currentTimeChange = new EventEmitter<number>();

  player: any;
  duration = 0;
  currentTime = 0;
  formattedTime = '00:00.000';
  isPlaying = false;
  frameRate = 29.97; // Default NTSC frame rate, adjust as needed
  private currentSrc = '';

  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'] && this.player) {
      const newSrc = this.options?.sources?.[0]?.src;
      if (newSrc && newSrc !== this.currentSrc) {
        this.currentSrc = newSrc;
        // Update video source
        this.updateVideoSource();
      }
    }
  }

  // Instantiate a Video.js player OnInit
  ngOnInit() {
    if (this.options?.sources?.[0]?.src) {
      this.currentSrc = this.options.sources[0].src;
    }

    this.initializePlayer();
  }

  private initializePlayer(): void {
    // Configure player with no controls and proper responsive behavior
    const videoJsOptions = {
      ...this.options,
      controls: false,
      bigPlayButton: false,
      controlBar: false,
      textTrackDisplay: false,
      errorDisplay: false,
      loadingSpinner: false,
      fluid: true,
      responsive: true,
      fill: true, // Fill the available width
      objectFit: 'contain', // Maintain aspect ratio without black bars
      techOrder: ['html5'],
      html5: {
        vhs: {
          overrideNative: true,
        },
        nativeVideoTracks: false,
        nativeAudioTracks: false,
        nativeTextTracks: false,
      },
      preload: 'auto', // Ensure metadata is loaded immediately
    };

    this.player = videojs(this.target.nativeElement, videoJsOptions, () => {
      // Player is ready
      this.setupPlayerEvents();
    });

    // Add click to play/pause
    this.player.on('click', () => {
      this.togglePlayPause();
    });
  }

  private setupPlayerEvents(): void {
    if (!this.player) {
      return;
    }

    this.player.on('loadedmetadata', () => {
      this.duration = this.player.duration();
      this.cdr.detectChanges();
    });

    // Force metadata loading
    this.player.load();

    // Manually try to get duration even before metadata event
    setTimeout(() => {
      if (this.duration === 0 && this.player.duration() > 0) {
        this.duration = this.player.duration();
        this.cdr.detectChanges();
      }
    }, 500);

    this.player.on('timeupdate', () => {
      this.currentTime = this.player.currentTime();
      this.formattedTime = this.formatTime(this.currentTime);
      this.currentTimeChange.emit(this.currentTime);
      this.cdr.detectChanges();
    });

    this.player.on('play', () => {
      this.isPlaying = true;
      this.cdr.detectChanges();
    });

    this.player.on('pause', () => {
      this.isPlaying = false;
      this.cdr.detectChanges();
    });
  }

  /**
   * Updates the video source when options change
   */
  private updateVideoSource(): void {
    if (this.player && this.options?.sources) {
      // Save current time to restore after source change
      const currentTime = this.player.currentTime();

      // Update the source
      this.player.src(this.options.sources);
      this.player.load();

      // Reset duration until metadata is loaded again
      this.duration = 0;

      // After source is loaded, restore time position if possible
      this.player.one('loadedmetadata', () => {
        this.duration = this.player.duration();

        // Only set time if within the video duration
        if (currentTime > 0 && currentTime < this.duration) {
          this.player.currentTime(currentTime);
        }

        this.cdr.detectChanges();
      });
    }
  }

  togglePlayPause() {
    if (this.player.paused()) {
      this.player.play();
      this.isPlaying = true;
    } else {
      this.player.pause();
      this.isPlaying = false;
    }
    this.cdr.detectChanges();
  }

  onTimelineChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    const newTime = parseFloat(value);
    this.player.currentTime(newTime);
    this.currentTimeChange.emit(newTime);
  }

  nextFrame() {
    this.player.pause();
    const frameTime = 1 / this.frameRate;
    const newTime = this.currentTime + frameTime;
    this.player.currentTime(newTime);
    this.currentTimeChange.emit(newTime);
  }

  previousFrame() {
    this.player.pause();
    const frameTime = 1 / this.frameRate;
    const newTime = Math.max(0, this.currentTime - frameTime);
    this.player.currentTime(newTime);
    this.currentTimeChange.emit(newTime);
  }

  /**
   * Sets the current time of the video player
   * @param seconds The time in seconds
   */
  setCurrentTime(seconds: number) {
    if (this.player && seconds >= 0 && (this.duration === 0 || seconds <= this.duration)) {
      this.player.currentTime(seconds);
    }
  }

  formatTime(seconds: number): string {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    const ms = Math.floor((seconds % 1) * 1000);
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}.${ms.toString().padStart(3, '0')}`;
  }

  // Dispose the player OnDestroy
  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
