import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Video } from '@ledsreact/data-models';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { environment } from '../../../../environments/environment';
import { AppService } from '../../../app.service';
import { VideoHttpService } from '@ledsreact/angular-http-services';

@Component({
  selector: 'app-video-wrapper',
  templateUrl: './video-wrapper.component.html',
  styleUrls: ['./video-wrapper.component.css'],
})
export class VideoWrapperComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() videos: Video[] = [];
  @Output() readonly currentTimeChange = new EventEmitter<number>();
  @Output() readonly selectedVideoChange = new EventEmitter<Video>();

  @ViewChild(VideoPlayerComponent) videoPlayer: VideoPlayerComponent;

  selectedIndex = 0;
  currentTime = 0;
  videoOptions = {
    fluid: true,
    aspectRatio: '16:9',
    autoplay: false,
    sources: [
      {
        src: '',
        type: 'video/mp4',
      },
    ],
  };
  videoAppUrl = environment.videoAppUrl;

  constructor(
    private readonly _appService: AppService,
    private _cdr: ChangeDetectorRef,
    private readonly _videoHttpService: VideoHttpService
  ) {
    if (!this.videoAppUrl) {
      this._appService.getRegion().then((region) => {
        if (region.includes('us-')) {
          this.videoAppUrl = 'https://video-us.ledsreact.com';
        } else if (region.includes('eu-')) {
          this.videoAppUrl = 'https://video-eu.ledsreact.com';
        }
      });
    }
  }

  ngOnInit(): void {
    this.updateVideoSource();
  }

  ngAfterViewInit(): void {
    this.updateVideoSource();
    this._cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['videos'] && !changes['videos'].firstChange) {
      this.updateVideoSource();
    }
  }

  /**
   * Updates the video source based on the selected video
   */
  private updateVideoSource(): void {
    if (this.videos && this.videos.length > 0) {
      // Ensure we have a valid index
      if (this.selectedIndex >= this.videos.length) {
        this.selectedIndex = 0;
      }

      const selectedVideo = this.videos[this.selectedIndex];

      if (!selectedVideo) {
        // Selected video is null or undefined
        return;
      }

      if (!selectedVideo.signedUrl) {
        // No signed URL available for selected video
        return;
      }

      this.videoOptions = {
        ...this.videoOptions,
        sources: [
          {
            src: selectedVideo.signedUrl,
            type: 'video/mp4',
          },
        ],
      };

      // Ensure the video player is updated with the new source
      this._cdr.detectChanges();

      // Emit the selected video
      this.selectedVideoChange.emit(selectedVideo);
    }
    // No videos available to select from
  }

  /**
   * Handles time changes from the video player
   * @param time Current video time in seconds
   */
  onVideoTimeChange(time: number): void {
    this.currentTime = time;
    this.currentTimeChange.emit(time);
  }

  /**
   * Selects a video by index
   * @param index The index of the video to select
   */
  selectVideo(index: number): void {
    if (index !== this.selectedIndex && index >= 0 && index < this.videos.length) {
      // Store current time to maintain position across videos
      const previousTime = this.currentTime;
      const wasPlaying = this.videoPlayer && !this.videoPlayer.player.paused();

      // If playing, pause first to avoid audio overlap
      if (wasPlaying && this.videoPlayer) {
        this.videoPlayer.player.pause();
      }

      // Update selected index
      this.selectedIndex = index;

      // Update video source
      this.updateVideoSource();

      // After the video is loaded, set it to the same time position
      setTimeout(() => {
        if (this.videoPlayer) {
          this.videoPlayer.setCurrentTime(previousTime);

          // If the video was playing before, resume playback
          if (wasPlaying) {
            setTimeout(() => this.videoPlayer.player.play(), 100);
          }
        }
      }, 600); // Increased delay to allow video to load
    }
  }

  /**
   * Formats video tab label
   * @param index The index of the video
   * @param video The video object
   * @returns Formatted label
   */
  getVideoTabLabel(index: number, video: Video): string {
    return `Video ${index + 1}`;
  }

  /**
   * Sets the current time of the video from external components (like graphs)
   * @param time Time in seconds to seek to
   */
  setCurrentTime(time: number): void {
    this.currentTime = time;
    if (this.videoPlayer) {
      this.videoPlayer.setCurrentTime(time);
    }
  }

  /**
   * Confirms and handles video deletion
   * @param index The index of the video to delete
   * @param video The video object to delete
   */
  confirmDeleteVideo(index: number, video: Video): void {
    if (confirm('Are you sure you want to delete this video?')) {
      this._videoHttpService.deleteVideo(video.id).subscribe({
        next: (response) => {
          if (response.success) {
            // Remove the video from the array
            this.videos = this.videos.filter((_, i) => i !== index);

            // If we deleted the currently selected video, select the first available one
            if (index === this.selectedIndex) {
              this.selectedIndex = Math.min(index, this.videos.length - 1);
            } else if (index < this.selectedIndex) {
              // Adjust selected index if we deleted a video before the current selection
              this.selectedIndex--;
            }

            // Update the video source
            this.updateVideoSource();
            this._cdr.detectChanges();
          }
        },
        error: (error) => {
          console.error('Error deleting video:', error);
          alert('Failed to delete video. Please try again.');
        },
      });
    }
  }
}
